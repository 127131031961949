import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import React, { useState } from "react";
import {
  PlusLg,
  Ticket,
  QrCodeScan,
  WindowSidebar,
  Person,
  Box,
  QuestionCircle,
  BoxArrowLeft,
  Globe,
  ListStars,
  Speedometer2,
  Archive,
  Calendar4,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useGetOrganizerServiceInfoQuery } from "../../qraphql/event.hooks";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export const languages = [
  { value: "en", label: "English" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "de", label: "Deutsch" },
  { value: "ar", label: "عرب" },
  { value: "th", label: "ไทย" },
  { value: "ko", label: "한국인" },
  { value: "ja", label: "日本" },
  { value: "it", label: "Italiano" },
  { value: "hi", label: "हिंदी" },
  { value: "ms", label: "Bahasa Melayu" },
  { value: "id", label: "Bahasa Indonesia" },
  { value: "es", label: "Español" },
  { value: "fr", label: "Français" },
  { value: "pt", label: "Português" },
  { value: "nl", label: "Nederlands" },
  { value: "sv", label: "Svenska" },
  { value: "pl", label: "Polski" },
  { value: "uk", label: "Українська" },
];

const ME_TICKET_LINK = process.env.REACT_APP_TICKET_URL;

export default function Menu() {
  const navigate = useNavigate();
  const { data, loading } = useGetOrganizerServiceInfoQuery();
  const [show, setShow] = useState(false);

  const { t, i18n } = useTranslation();
  const isProOrganizeer = data?.activeSubscription;

  const locale =
    i18n.language.split("-")[0] === "en"
      ? ""
      : `/${i18n.language.split("-")[0]}`;

  const lang = i18n.language.split("-")[0];

  const changeLanguage = (lng: string) => {
    const queryParams = new URLSearchParams(location.search).toString();

    const currentPath = location.pathname
      .split("/")
      .slice(i18n.language.split("-")[0] === "en" ? 1 : 2)
      .join("/");

    const newPath = lng === "en" ? `/${currentPath}` : `/${lng}/${currentPath}`;
    const finalPath = queryParams ? `${newPath}?${queryParams}` : newPath;

    navigate(finalPath, { replace: true });
    i18n.changeLanguage(lng);
  };

  if (data?.needVerifyEmail) {
    window.location.href = `${process.env.REACT_APP_TICKET_URL}${locale}/for-organizers/profile`;
  }

  return (
    <>
      <div className="col-12 col-lg-3 col-xl-2 bg-white border-end">
        <div className="col-12 col-lg-3 col-xl-2 d-none d-lg-flex admin-left-menu p-4 h-100 flex-column justify-content-between fixed-top">
          <div className="">
            <div className=" text-center">
              <a
                href={ME_TICKET_LINK + locale + "/for-organizers/"}
                className="pb-4 d-inline-block"
              >
                <img
                  src="https://me-ticket.com/assets/img/logo.svg"
                  alt="Me ticket"
                  width="125"
                  height="40"
                />
              </a>
            </div>
            <small>
              <p className="pt-2">{t("menu.main")}</p>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a
                    className="nav-link text-success text-capitalize"
                    aria-current="page"
                    href={locale + "/create-event"}
                  >
                    <PlusLg className="pe-2 fs-4" /> {t("menu.create")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link"
                    href={ME_TICKET_LINK + locale + "/for-organizers/my-events"}
                  >
                    <Ticket className="pe-2 fs-4" /> {t("menu.events")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link "
                    href={
                      ME_TICKET_LINK +
                      locale +
                      "/for-organizers/ticket-scanner-app"
                    }
                  >
                    <QrCodeScan className="pe-2 fs-4" /> {t("menu.ticket")}
                  </a>
                </li>
                <li className="nav-item mb-2 ">
                  <a
                    className={classNames("nav-link", {
                      "opacity-25 disabled": !isProOrganizeer || loading,
                    })}
                    href={ME_TICKET_LINK + locale + "/for-organizers/statistic"}
                  >
                    <WindowSidebar className="pe-2 fs-4" />{" "}
                    {t("menu.statistics")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link "
                    href={ME_TICKET_LINK + locale + "/for-organizers/profile"}
                  >
                    <Person className="pe-2 fs-4" /> {t("menu.profile")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link "
                    href={
                      ME_TICKET_LINK +
                      locale +
                      "/for-organizers/payment-subscription"
                    }
                  >
                    <Calendar4 className="pe-2 fs-4" />{" "}
                    {t("menu.Payment & Subscription")}
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className="nav-link "
                    href={
                      ME_TICKET_LINK + locale + "/for-organizers/archive/events"
                    }
                  >
                    <Box className="pe-2 fs-4" /> {t("menu.archive")}
                  </a>
                </li>
                <li className="nav-item mb-3">
                  <a
                    className="nav-link "
                    target="_blank"
                    href={ME_TICKET_LINK + locale + "/organizer-support-chat"}
                    rel="noopener noreferrer"
                  >
                    <QuestionCircle className="pe-2 fs-4" /> {t("menu.support")}
                  </a>
                </li>
              </ul>
            </small>
          </div>
          <ul className="nav pt-3 pb-4">
            <li className="nav-item">
              <a
                className="nav-link"
                href={ME_TICKET_LINK + locale + "/for-organizers/logout"}
              >
                <BoxArrowLeft className="fs-4 pe-2" />
                <small>{t("menu.logout")}</small>
              </a>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle
                  // variant="success"
                  id="dropdown-basic"
                  variant="link"
                  className="nav-link dropdown-toggle text-uppercase"
                >
                  <Globe className="fs-4 pe-2" />
                  <small>{lang}</small>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {languages.map((item) => (
                    <Dropdown.Item
                      key={item.value}
                      onClick={() => changeLanguage(item.value)}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
        <nav className="navbar bg-white fixed-top d-lg-none">
          <div className="container-fluid">
            <button
              onClick={() => setShow(true)}
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a
              className="navbar-brand"
              href={ME_TICKET_LINK + locale + "/for-organizers/"}
            >
              <img
                src="https://me-ticket.com/assets/img/logo.svg"
                alt="Me ticket"
                width="125"
                height="40"
              />
            </a>
            <div className="d-flex align-items-center">
              {/*  <div
                data-controller="org-notification"
                data-org-notification-url-value="/for-organizers/ajax/notification/"
              >
                <button
                  className="d-lg-none me-3 border-0 org-notification-btn-mob rounded-circle position-relative pt-1"
                  data-org-notification-target="notificationButton"
                  data-action="click->org-notification#open"
                >
                  <Bell className="fs-3" />
                  <span
                    className="position-absolute top-25 end-0 translate-middle p-1 bg-danger border border-white rounded-circle d-none"
                    data-org-notification-target="newAlerts"
                  >
                    <span className="visually-hidden">New alerts</span>
                  </span>
                </button>
                <div
                  className="d-lg-none d-none position-absolute bg-white shadow p-3 rounded-1 org-notification-wrapper"
                  data-org-notification-target="notificationModal"
                >
                  <div
                    className="tooltip-arrow"
                    style={{
                      position: "absolute",
                      left: 0,
                      transform: "translate3d(59.5px, 0px, 0px)",
                    }}
                  ></div>
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <h5 className="fs-5 mb-0 text-dark">
                      Notifications{" "}
                      <span>
                        (
                        <span data-org-notification-target="unreadCount">
                          0
                        </span>
                        )
                      </span>
                    </h5>
                    <button
                      type="button"
                      className="btn-close fw-normal fs-7"
                      data-action="click->org-notification#close"
                    ></button>
                  </div>
                  <div
                    className="d-none"
                    data-org-notification-target="notification"
                  >
                    <div
                      className="org-notification-wrapper-content scrollable-menu"
                      data-org-notification-target="list"
                    ></div>
                  </div>
                  <div
                    className="text-center py-3"
                    data-org-notification-target="noNotifications"
                  >
                    <img
                      src="/assets/img/no-notification.svg"
                      alt="No notification"
                    />
                    <p className="text-info fs-7 mt-3 mb-0">
                      No Notifications Yet
                    </p>
                  </div>
                  <div
                    className="p-3 org-notification-item mt-2 d-none"
                    data-org-notification-target="template"
                    data-action="click->org-notification#read"
                  >
                    <p className="mb-2 msg"></p>
                    <div className="d-flex justify-content-between">
                      <p className="fs-7 mb-0 text-info">
                        <span className="time"></span> · <span>ME Ticket</span>
                      </p>
                      <button
                        className="text-success border-0 fs-7 bg-transparent"
                        data-action="click->org-notification#remove"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <a
                href="/for-organizers/profile"
                className="text-decoration-none"
              >
                <div className="d-xl-none d-lg-none d-flex avatar rounded-5">
                  <small>Profile</small>
                </div>
              </a> */}
            </div>
            <Offcanvas
              show={show}
              onHide={() => setShow(false)}
              placement="end"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  <img
                    src="https://me-ticket.com/assets/img/logo.svg"
                    alt="Me ticket"
                    width="126"
                    height="40"
                  />
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShow(false)}
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="nav flex-column pt-3">
                  <li className="nav-item">
                    <a
                      className="nav-link text-success text-capitalize"
                      aria-current="page"
                      href={locale + "/create-event"}
                    >
                      <PlusLg className="me-2" /> {t("menu.create")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href={
                        ME_TICKET_LINK + locale + "/for-organizers/my-events"
                      }
                    >
                      <ListStars className="me-2" /> {t("menu.events")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href={
                        ME_TICKET_LINK +
                        locale +
                        "/for-organizers/ticket-scanner-app"
                      }
                    >
                      <QrCodeScan className="me-2" /> {t("menu.ticket")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={classNames("nav-link", {
                        "opacity-25 disabled": !isProOrganizeer || loading,
                      })}
                      href={
                        ME_TICKET_LINK + locale + "/for-organizers/statistic"
                      }
                    >
                      <Speedometer2 className="me-2" /> {t("menu.statistics")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href={ME_TICKET_LINK + locale + "/for-organizers/profile"}
                    >
                      <Person className="me-2" /> {t("menu.profile")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href={
                        ME_TICKET_LINK +
                        locale +
                        "/for-organizers/payment-subscription"
                      }
                    >
                      <Calendar4 className="me-2" />{" "}
                      {t("menu.Payment & Subscription")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href={
                        ME_TICKET_LINK +
                        locale +
                        "/for-organizers/archive/events"
                      }
                    >
                      <Archive className="me-2" /> {t("menu.archive")}
                    </a>
                  </li>
                </ul>
                <ul className="nav pt-5">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={ME_TICKET_LINK + locale + "/for-organizers/logout"}
                    >
                      <BoxArrowLeft className="me-1" />
                      {t("menu.logout")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <Dropdown>
                      <Dropdown.Toggle
                        // variant="success"
                        id="dropdown-basic"
                        variant="link"
                        className="nav-link dropdown-toggle text-uppercase"
                      >
                        <Globe className="fs-4 me-1" />
                        <small>{lang}</small>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {languages.map((item) => (
                          <Dropdown.Item
                            key={item.value}
                            onClick={() => changeLanguage(item.value)}
                          >
                            {item.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </Offcanvas>
          </div>
        </nav>
      </div>
    </>
  );
}
