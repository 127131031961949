import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { languages } from "../menu";

const Layout = () => {
  const { locale = "en" } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (languages.find((el) => el.value === locale)) {
      i18n.changeLanguage(locale);
    } else {
      navigate("/create-event", { replace: true });
    }
  }, [locale, i18n, navigate]);

  return <Outlet />;
};

export default Layout;
