import classNames from "classnames";
import { ErrorMessage, useField } from "formik";
import React from "react";
import Select, { Props, StylesConfig } from "react-select";

type OptionType = { value: string; label: string };

interface ISelectProps extends Props<OptionType> {
  label: string;
  name: string;
}

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    padding: "17px 5px 3px 5px",
    borderRadius: 4,
    borderColor: "#DDE0E4",
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    boxShadow: "none",
    borderRadius: 0,
    border: "1px solid #ced4da",
    borderTop: "none",
    backgroundColor: "white",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 4px",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#F3F6FD",
    color: "#6D819B",
    fontSize: "14px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#6D819B",
    fontSize: "14px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ":hover": {
      backgroundColor: "#F3F6FD",
      color: "#6D819B",
    },
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: "-8px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#3E4957",
  }),
};

const labelStyles = {
  color: "#8B929A",
  transformOrigin: "0px 0px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  paddingTop: "1rem",
  paddingLeft: "0.75rem",
  zIndex: 2,
  transform: "scale(0.85) translateY(-0.5rem) translateX(0.15rem)",
};

export default function SelectInput({
  label,
  className,
  name,
  ...props
}: ISelectProps) {
  const [, meta] = useField(name);

  return (
    <div className={`${className} position-relative`} translate="no">
      <Select
        styles={customStyles}
        {...props}
        components={{
          IndicatorSeparator: () => null,
        }}
        className={classNames({
          "border border-danger": meta.touched && !!meta.error,
          "border border-success": meta.touched && !meta.error,
        })}
        menuPortalTarget={document.body}
      />
      <label className="position-absolute top-0 start-0" style={labelStyles}>
        {label}
      </label>
      <ErrorMessage
        name={name}
        component="div"
        className="text-danger error-field"
      />
    </div>
  );
}
