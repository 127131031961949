import React from "react";
import Select, { Props, StylesConfig } from "react-select";

type OptionType = { value: string; label: string };

interface ISelectProps extends Props<OptionType> {}

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: 4,
    borderColor: "#DDE0E4",
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    boxShadow: "none",
    borderRadius: 0,
    border: "1px solid #ced4da",
    borderTop: "none",
    backgroundColor: "white",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#F3F6FD",
    color: "#6D819B",
    fontSize: "14px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#6D819B",
    fontSize: "14px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ":hover": {
      backgroundColor: "#F3F6FD",
      color: "#6D819B",
    },
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  placeholder: (provided) => ({
    ...provided,
    color: "#3E4957",
  }),
};

export default function SelectSmall({ ...props }: ISelectProps) {
  return (
    <div translate="no">
      <Select
        styles={customStyles}
        {...props}
        components={{
          IndicatorSeparator: () => null,
        }}
        menuPortalTarget={document.body}
      />
    </div>
  );
}
