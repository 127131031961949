import { ErrorMessage, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner, Button } from "react-bootstrap";
import { Plus, Pencil, Trash, InfoCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { base64ToFile, imageFileToBase64 } from "../../../helpers";
import {
  useGetOrganizerServiceInfoQuery,
  useDeleteEventBannerImageMutation,
} from "../../../qraphql/event.hooks";
import { IEntity } from "../../../types";
import classNames from "classnames";
import useGetUrlLocale from "../../../hooks/useGetUrlLocale";

export default function Banner() {
  const { t } = useTranslation();
  const urlLocale = useGetUrlLocale();
  const { setFieldValue, values } = useFormikContext<IEntity>();
  const addBannerImgRef = useRef<HTMLInputElement>(null);
  const { data, loading } = useGetOrganizerServiceInfoQuery();

  const [imageBanner, setImageBanner] = useState("");
  const { id } = useParams();
  const [deleteEventBannerImage] = useDeleteEventBannerImageMutation();
  const [errorSize, setErrorSize] = useState(false);

  const isProOrganizeer = data?.activeSubscription;

  useEffect(() => {
    if (values.banner) {
      imageFileToBase64(values.banner.file).then((base64) => {
        setImageBanner(base64);
      });
    }
  }, [values.banner, id]);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setErrorSize(true);
        return;
      } else {
        setErrorSize(false);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setImageBanner(result);
        setFieldValue("banner", {
          id: "image",
          file: base64ToFile(result, "banner"),
        });
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    }
  };
  const handleDeleteBanner = () => {
    if (!isProOrganizeer) return;
    if (!values.banner.id.includes("image")) {
      try {
        deleteEventBannerImage({ variables: { eventId: values.id } });
      } catch (error) {}
    }

    setImageBanner("");
    setFieldValue("banner", null);
  };

  const handleEditBanner = () => {
    isProOrganizeer && addBannerImgRef.current?.click();
  };

  if (loading) {
    return (
      <div className="pt-5 d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <Row className="mt-md-5 mt-4 gy-3">
        {!isProOrganizeer && (
          <Col xs={12}>
            <div
              className="bg-success-bg text-success rounded d-flex justify-content-between align-items-center gap-3"
              style={{ padding: 10 }}
            >
              <div className="d-flex">
                <div>
                  <InfoCircle className="pe-2 fs-4" height={26} width={26} />
                </div>
                {t(
                  "firstStep.To set up main event banner upgrade your plan to Pro"
                )}
              </div>
              <Button
                as="a"
                href={`${process.env.REACT_APP_TICKET_URL}${urlLocale}/for-organizers/payment-subscription`}
                variant="success"
                className="fs-7 py-1 px-3 d-flex justify-content-center align-items-center"
                // style={{ height: 29 }}
              >
                {t("firstStep.Upgrade to Pro")}
              </Button>
            </div>
          </Col>
        )}
        <Col
          xs={12}
          md={5}
          className={classNames({
            "opacity-25": !isProOrganizeer,
          })}
        >
          <div className="fs-8 mb-2 fw-bold">
            {t("firstStep.banner")}{" "}
            <span className="fw-normal text-info fst-italic">
              - {t("firstStep.optional")}
            </span>
          </div>
          <div className="text-info fs-7">{t("firstStep.bannerInfo")}</div>
        </Col>
        <Col
          xs={12}
          md={7}
          className={classNames({
            "opacity-25 ": !isProOrganizeer,
            "cursor-pointer": isProOrganizeer,
          })}
        >
          {imageBanner ? (
            <div
              className="position-relative rounded-3 p-1 border w-100"
              style={{ height: 176 }}
            >
              <img
                src={imageBanner}
                alt="img banner"
                className="rounded-3"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <div className="position-absolute top-50 start-50 translate-middle d-flex gap-3">
                <div
                  className="rounded-circle bg-white d-flex justify-content-center align-items-center"
                  style={{ width: 32, height: 32 }}
                  onClick={handleEditBanner}
                >
                  <Pencil />
                </div>
                <div
                  className="rounded-circle bg-white d-flex justify-content-center align-items-center"
                  style={{ width: 32, height: 32 }}
                  onClick={handleDeleteBanner}
                >
                  <Trash />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="rounded-3 p-1 dashed-button w-100"
                style={{ height: 176 }}
                onClick={() =>
                  isProOrganizeer && addBannerImgRef.current?.click()
                }
              >
                <div
                  className={classNames(
                    "h-100 rounded-3 d-flex justify-content-center align-items-center flex-column",
                    {
                      "bg-secondary": !isProOrganizeer,
                      "bg-success-light text-success": isProOrganizeer,
                    }
                  )}
                >
                  <Plus className="fs-2" />
                  {t("firstStep.addBanner")}
                </div>
              </div>
            </>
          )}
          {errorSize && (
            <div className="text-danger error-field">
              {t("firstStep.maxSize")} 10 MB
            </div>
          )}
          <ErrorMessage
            name="banner"
            component="div"
            className="text-danger error-field"
          />
          <input
            ref={addBannerImgRef}
            type="file"
            style={{ display: "none" }}
            accept=".png, .jpg, .jpeg, .webp"
            onChange={handleImageChange}
          />
        </Col>
      </Row>
    </>
  );
}
